import React, { Component } from "react";
export const jwt = JSON.parse(localStorage.getItem("jwt"));
export const userName = JSON.parse(localStorage.getItem("userName"));
export const login_detail = JSON.parse(localStorage.getItem("login_detail"));
export const passHeader = { token: jwt };
export const videoPath = "https://cdn.simulanis.io/videoplayer/"

export const logout = {
  logout() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/login";
  },
};